import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const PriceStyles = withTheme(() => (
  <Global
    styles={css`
      section.background-color-Dark {
        .leshen-price-change span {
          color: white !important;
        }
      }
    `}
  />
))

export default PriceStyles
